import { Swiper, Autoplay, Navigation, Pagination } from 'swiper';

export default function setupSlider(className, type) {
  if (type === 'hero') {
    new Swiper(className, {
      modules: [Autoplay, Pagination],
      direction: 'horizontal',
      spaceBetween: 22,
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      pagination: {
        el: '.hero__pagination',
        clickable: true
      },
      breakpoints: {
        768: {
          direction: 'vertical',
          spaceBetween: 30
        }
      }
    });
  }

  if (type === 'events') {
    const slider = new Swiper(className, {
      modules: [Autoplay, Navigation, Pagination],
      loop: true,
      slidesPerView: 1.15,
      spaceBetween: 22,
      allowTouchMove: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: true
      },
      navigation: {
        prevEl: '.events__navigation--prev',
        nextEl: '.events__navigation--next'
      },
      pagination: {
        el: '.events__pagination',
        clickable: true
      },
      breakpoints: {
        576: {
          slidesPerView: 1.45
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        1200: {
          slidesPerView: 3,
          allowTouchMove: false
        }
      }
    });

    const swiperContainer = document.querySelector(className);
    if (swiperContainer) {
      swiperContainer.addEventListener('mouseenter', () => {
        slider.autoplay.stop();
      });

      swiperContainer.addEventListener('mouseleave', () => {
        slider.autoplay.start();
      });
    }
  }

  if (type === 'partners') {
    const slider = new Swiper(className, {
      modules: [Autoplay, Navigation, Pagination],
      loop: true,
      slidesPerView: 1.5,
      spaceBetween: 18,
      autoplay: {
        delay: 2500,
        disableOnInteraction: true
      },
      navigation: {
        prevEl: '.partners__navigation--prev',
        nextEl: '.partners__navigation--next'
      },
      pagination: {
        el: '.partners__pagination',
        clickable: true
      },
      breakpoints: {
        575: {
          slidesPerView: 2.25
        },
        768: {
          slidesPerView: 3
        },
        1200: {
          slidesPerView: 5
        }
      }
    });

    const swiperContainer = document.querySelector(className);
    if (swiperContainer) {
      swiperContainer.addEventListener('mouseenter', () => {
        slider.autoplay.stop();
      });

      swiperContainer.addEventListener('mouseleave', () => {
        slider.autoplay.start();
      });
    }
  }
}
