import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.config({ ignoreMobileResize: true });

export default function setupParallax(className) {
  const items = document.querySelectorAll(className);
  if (!items || !items.length) return;

  [...items].forEach((item) => {
    const image = item.querySelector('img');

    gsap.to(image, {
      yPercent: 8,
      xPercent: -4,
      ease: 'power1.out',
      scrollTrigger: {
        trigger: item,
        start: 'top bottom',
        scrub: 1
      }
    });
  });
}
