export default function setHeaderScroll(className) {
  const header = document.querySelector(className);
  if (!header) return;

  window.addEventListener('scroll', () => {
    if (window.pageYOffset > 0) {
      header.classList.add('scroll');
    } else {
      header.classList.remove('scroll');
    }
  });
}
