function openNavigation(header, bars, menu) {
  const headerHeight = header.offsetHeight;
  const windowHeight = window.innerHeight;

  bars.classList.add('close');

  document.body.style.overflow = 'hidden';

  menu.classList.add('visible');
  menu.style.maxHeight = `${windowHeight - headerHeight}px`;
}

function closeNavigation(bars, menu) {
  bars.classList.remove('close');

  document.body.style.overflow = '';

  menu.classList.remove('visible');

  setTimeout(() => {
    menu.style.maxHeight = '0';
  }, 400);
}

function recalculateHeight(header, menu) {
  const headerHeight = header.offsetHeight;
  const windowHeight = window.innerHeight;

  menu.style.maxHeight = `${windowHeight - headerHeight}px`;
}

export default function setupMobileMenu() {
  const header = document.querySelector('.site-header');
  const bars = document.querySelector('#bars-mobile-menu');
  const navigation = document.querySelector('.site-header-navigation');
  if (!header || !bars || !navigation) return;

  bars.addEventListener('click', () => {
    const closed = !bars.classList.contains('close');

    if (closed) openNavigation(header, bars, navigation);
    if (!closed) closeNavigation(bars, navigation);
  });

  window.addEventListener('scroll', () => {
    const closed = !bars.classList.contains('close');

    if (!closed) {
      recalculateHeight(header, navigation);
    }
  });

  const links = navigation.querySelectorAll('li a');
  if (links && links.length) {
    [...links].forEach((link) => {
      link.addEventListener('click', (event) => {
        const href = link.getAttribute('href');

        if (href.includes('#')) {
          event.preventDefault();

          const section = document.querySelector(href);
          if (section) {
            window.scrollTo({
              top: section.offsetTop - 80,
              behavior: 'smooth'
            });
          }
        }

        if (window.innerWidth <= 1199) {
          closeNavigation(bars, navigation);
        }
      });
    });
  }
}
