import setHeaderScroll from './modules/header';
import setupMobileMenu from './modules/mobile-menu';
import setupSlider from './modules/slider';
import setupFadeUp from './modules/fade-up';
import setupParallax from './modules/parallax';
import setupSingleParallax from './modules/single-parallax';
import setupPopup from './modules/popup';
import '../scss/styles.scss';

window.scrollTo(0, 0);

document.addEventListener('DOMContentLoaded', () => {
  setHeaderScroll('.site-header');
  setupMobileMenu();

  setupSlider('.hero__images-slider', 'hero');
  setupSlider('.events__list', 'events');
  setupSlider('.partners__list', 'partners');

  setupFadeUp('.fade-up');
  setupParallax('.parallax');

  setupSingleParallax('.single-thumbnail > img');
  setupSingleParallax('.single-content figure > img');

  setupPopup('.events__list', '#site-post-popup');
});
