const ajaxurl = window.location.href.includes('/new')
  ? `${window.location.origin}/new`
  : window.location.origin;

async function placePopupData(id) {
  try {
    const response = await fetch(`${ajaxurl}/wp-admin/admin-ajax.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        action: 'get_page_content',
        page_id: id
      })
    });
    const data = await response.json();
    if (data.success) return data.data;
  } catch (error) {
    console.error(error);
  }
}

function getPopupAreas(popup, popupId) {
  const placement = {};

  const title = popup.querySelector(`${popupId}-title`);
  if (title) placement.title = title;

  const thumbnail = popup.querySelector(`${popupId}-thumbnail`);
  if (thumbnail) placement.thumbnail = thumbnail;

  const content = popup.querySelector(`${popupId}-content`);
  if (content) placement.content = content;

  return placement;
}

async function showPopup(popup, id, popupId) {
  const data = await placePopupData(id);
  if (!data) return;

  const { title, thumbnail, content } = data;

  const placement = getPopupAreas(popup, popupId);

  if (placement.title) placement.title.innerHTML = title;
  if (placement.thumbnail && thumbnail) {
    placement.thumbnail.innerHTML = '';
    const image = document.createElement('IMG');
    image.src = thumbnail;
    image.alt = title;

    placement.thumbnail.append(image);
  }
  if (placement.content) placement.content.innerHTML = content;

  document.body.style.overflowY = 'hidden';

  popup.classList.add('visible');
}

function hidePopup(popup) {
  document.body.style.overflowY = '';

  popup.classList.remove('visible');
}

export default function setupPopup(targetClass, popupId) {
  const popup = document.querySelector(popupId);
  if (!popup) return;

  const section = document.querySelector(targetClass);
  if (!section) return;

  const triggers = section.querySelectorAll('.popup-trigger');
  if (!triggers || !triggers.length) return;

  [...triggers].forEach((trigger) => {
    trigger.addEventListener('click', (event) => {
      event.stopPropagation();

      const id = trigger.dataset.post;
      if (!id) return;

      showPopup(popup, id, popupId);
    });
  });

  const times = popup.querySelector(`${popupId}-times`);
  if (times) {
    times.addEventListener('click', (event) => {
      event.stopPropagation();

      hidePopup(popup);
    });
  }
}
