import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.config({ ignoreMobileResize: true });

export default function setupFadeUp(className) {
  const items = document.querySelectorAll(className);
  if (!items || !items.length) return;

  [...items].forEach((item) => {
    gsap.timeline({
      scrollTrigger: {
        trigger: item,
        start: 'top bottom',
        once: true,
        onEnter: () => item.classList.add('animated')
      }
    });
  });
}
